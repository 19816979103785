import React from "react"
import { Box, Chip, createStyles, makeStyles, Theme } from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
    },
  })
)

interface FilterProps {}
const Filter: React.FC<FilterProps> = () => {
  const classes = useStyles()
  return (
    <Box display="flex" justifyContent="flex-end">
      {["Herren 1", "Herren", "Damen"].map(f => (
        <Chip
          key={f}
          onClick={() => {
            alert("do sth")
          }}
          className={classes.chip}
          label={f}
          clickable
          color="primary"
          onDelete={() => {
            alert("TODO do sth.")
          }}
          deleteIcon={<DoneIcon />}
        />
      ))}
    </Box>
  )
}

export default Filter
