import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Container, Grid, makeStyles } from '@material-ui/core'

import Filter from '../components/News/Filter'
import ImageCard from '../components/Shared/ImageCard'
import useSEO from '../components/Shared/useSEO'
import { ReportsQuery } from '../../graphql-types'
const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
}))

interface NewsProps {
    data: ReportsQuery
}

export default function News({ data }: NewsProps) {
    useSEO({ title: 'News' })
    const classes = useStyles()
    const [filters, setFilters] = useState([])

    return (
        <Container maxWidth="md" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Filter />
                </Grid>
                {data.allSanityReport.nodes.map(report => {
                    return (
                        <Grid key={report.id} item xs={12} sm={6} md={4}>
                            <ImageCard
                                linkTo={report.slug.current}
                                img={report.mainImage}
                                category={report.category.title}
                                title={report.title}
                                date={report.publishedAt}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Container>
    )
}

export const query = graphql`
    query Reports {
        allSanityReport(sort: { fields: publishedAt, order: DESC }) {
            nodes {
                id
                slug {
                    current
                }
                title
                publishedAt(formatString: "DD. MMMM YYYY", locale: "de")
                category {
                    title
                }
                mainImage {
                    ...ImageWithPreview
                }
            }
        }
    }
`
